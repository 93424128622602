




















































import { Vue, PropSync, Component, Watch, Prop, Inject } from "vue-property-decorator";
import ConfigurationService, {
  AllStatusesConfig,
  MetadataValue,
  SingleStatusConfig
} from "@/services/configuration-service";
import config from "@/config";

import { VuetifyForm } from "@/VuetifyTypes";
import { MetadataValuesModel } from "@/services/status-service";

interface MetadataUIValueModel {
  key: string;
  value: string;
  displayValues: MetadataValue[];
  type: string;
  isRequired: boolean;
}

@Component({
  inheritAttrs: false
})
export default class StatusConfigDialog extends Vue {
  @Inject() ConfigurationService!: ConfigurationService;

  @PropSync("dialog", { required: true }) dialogProp!: boolean;
  @Prop({ required: true }) myConfigTemplate!: SingleStatusConfig;
  statusMetadataConfig: AllStatusesConfig = { statuses: [] };

  async mounted() {
    this.statusMetadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  getStatusName() {
    return this.myConfigTemplate
      ? config.caseStatus.find(x => x.value == this.myConfigTemplate.id)?.name
      : "";
  }

  metadataValues: MetadataUIValueModel[] = [];
  note = "";
  formValid = false;

  @Watch("myConfigTemplate", { deep: true })
  resetModel() {
    this.note = "";
    this.metadataValues = this.myConfigTemplate.metadata.map(meta => {
      if (meta.type === "freetext") {
        return {
          key: meta.name,
          value: "",
          displayValues: [],
          type: meta.type,
          isRequired: meta.isRequired
        };
      } else {
        //dropdown
        return {
          key: meta.name,
          value: "",
          displayValues: meta.values,
          type: meta.type,
          isRequired: true
        };
      }
    });
  }

  validateMetadataField(metaDataValue: MetadataUIValueModel, maybeInput: any) {
    if (maybeInput) return true;
    if (!metaDataValue.isRequired) return true;

    return "This is required to continue";
  }

  validateNoteField(noteIsRequired: boolean, maybeInput: any) {
    if (maybeInput) return true;
    if (!noteIsRequired) return true;

    return "You must add a note to continue";
  }

  validateDropdown(input: any) {
    if (input) return true;
    return "Please select an option";
  }

  save() {
    const castedForm = this.$refs.form as VuetifyForm;

    if (!castedForm.validate()) {
      return;
    }

    const modelToSendBack: MetadataValuesModel = {
      note: this.note,
      metadata: this.metadataValues.map(x => {
        const selectedDisplayName = this.myConfigTemplate.metadata
          .find(y => y.name == x.key)
          ?.values.find(z => z.value == x.value)?.displayName;
        return {
          key: x.key,
          value: x.value,
          displayValue: selectedDisplayName ? selectedDisplayName : ""
        };
      })
    };
    this.$emit("update-case-status", modelToSendBack);
  }

  cancel() {
    this.$emit("cancel");
    this.resetModel();
  }
}
